import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import Plaatjie from '@ubo/plaatjie'
import Share from 'components/elements/Misc/Share'
import ParseContent from 'components/shared/ParseContent'

// Images
import Quote from 'img/quote.inline.svg'

const Banner = styled.div``

const BannerLower = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  @media (min-width: 992px) {
    height: 750px;
  }
`

const QuoteWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  z-index: 2;

  text-align: center;
  @media (min-width: 992px) {
    margin-top: -12.75rem;
    margin-bottom: -4rem;
    padding: 4rem 3.3rem;
  }
  @media (max-width: 991px) {
    margin-top: 2rem;
    padding: 2rem 1.3rem;
  }
`

const Content = styled(ParseContent)`
  & blockquote {
    font-size: 36px;
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const Icon = styled.div`
  top: -25px;
  left: 45px;
`

const VideoWrapper = styled.div`
  padding-top: 56.25%;
  z-index: 2;
`

const Iframe = styled.iframe`
  top: 0;
  left: 0;
`

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      flexContent: any
      projectrecap: any
      projectcategories: any
    }
  }
  location?: any
  prefix?: string
  pageContext?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { flexContent, projectrecap, projectcategories, title, seo },
  },
  location = {},
  prefix = 'Project_Flexcontent',
  pageContext,
}) => (
  <Layout>
    <SEO seo={seo} />
    <section className="mb-5 pb-lg-5">
      <Banner className="position-relative">
        {projectrecap.bannervideo && (
          <VideoWrapper className="position-relative overflow-hidden">
            <Iframe
              title="Gooiland Elektro header"
              src={`${projectrecap.bannervideo}&muted=1&loop=1&autoplay=1&background=1`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              loading="lazy"
              allow="autoplay"
              className="position-absolute"
            />
          </VideoWrapper>
        )}
        {!projectrecap.bannervideo && (
          <Image image={projectrecap?.image} alt="" />
        )}
      </Banner>
      <BannerLower className="py-4">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6">
              <Share
                heading="Deel dit project:"
                location={location}
                title={title || 'Project van Kokall'}
                body={projectrecap?.excerpt}
                quote={projectrecap?.quote}
                className="d-flex align-items-center "
              />
            </div>
            <div className="col-lg-6">
              <QuoteWrapper className="position-relative">
                <Icon className="position-absolute">
                  <Quote />
                </Icon>
                <Content content={projectrecap?.quote} />
              </QuoteWrapper>
            </div>
          </div>
        </div>
      </BannerLower>
    </section>

    <ProjectContext.Provider
      value={{ projectCategory: projectcategories?.nodes[0].name }}
    >
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </ProjectContext.Provider>
  </Layout>
)

export const pageQuery = graphql`
  query projectById($id: String!) {
    page: wpProject(id: { eq: $id }) {
      ...generalProjectFragment
    }
  }
`

export interface ProjectContextProps {
  projectCategory?: string
}

export const ProjectContext = React.createContext({
  projectCategory: '',
})

export default PageTemplate
